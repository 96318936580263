import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { above } from '../styles/utilities';

const StaggeredImageContent = props => {

  if(props.orientation === 'contentFirst') {
    return (
      <section className={`${props.className} container section--content content-first`}>
        <div className="flex-container">
          <div className="content-wrap">
            <div>
              { props.children }
            </div>
          </div>
          <div className="img-wrap">
            <Img 
              durationFadeIn={125}
              alt={props.imageAlt}
              fluid={props.image}
              loading="lazy"
              style={{
                bottom: 0,
                position: 'absolute',
                top: 0,
                width: '100%'
              }}
            />
          </div>
        </div>
      </section>
    )
  }

  else {
    return (
      <section className={`${props.className} container section--content image-first`}>
        <div className="flex-container">
          <div className="img-wrap">
            <Img
              durationFadeIn={125}
              alt={props.imageAlt}
              fluid={props.image}
              loading="lazy"
              style={{
                bottom: 0,
                position: 'absolute',
                top: 0,
                width: '100%'
              }}
            />
          </div>
          <div className="content-wrap">
            <div>
              { props.children } 
            </div>
          </div>
        </div>
      </section>
    )
  }

}

export default styled(StaggeredImageContent)`

  margin-bottom: 75px;
  margin-top: 75px;
  text-align: center;

  ${above.lg`
    text-align: left;
  `}

  ${above.lg`
    margin-bottom: 75px;
    &:first-of-type {
      margin-top: 150px;
    }
  `}

  .content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${above.lg`
      padding: 150px 50px;
    `}
  }

  .img-wrap {
    display: flex;
    flex: 0 0 52.5%;
    margin-bottom: 15px;
    min-height: 150px;
    position: relative;

    ${above.sm`
      min-height: 250px;
    `}

    img {
      object-position: top center !important;
      ${above.lg`
        object-position: center center !important;
      `}
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    ${above.lg`
      flex-direction: row;
    `}
  }

  &.content-first {
    .img-wrap {
      order: 1;
      ${above.lg`
        img {
          padding-right: 50px;
        }
        order: 2;
      `}
    }
    .content-wrap {
      order: 2;
      ${above.lg`
        order: 1;
      `}
    }
  }

  &.image-first {
    .img-wrap {
      ${above.lg`
        img {
          padding-left: 50px;
        }
      `}
    }
  }

  &.container {
    ${above.lg`
      max-width: 100%;
    `}
  }
  
`;