import React from 'react';
import { Link } from 'gatsby';
import LinkCard from './LinkCard';
import Icons from './ServicesIcons';
import styled from 'styled-components';
import { above } from '../styles/utilities';

const Services = props => ( 
  <section className={ props.className }>
  <div className="section--services">
    <div className="container">

      <div className="row justify-content-between">

        <div className="col-xs-12 col-lg-5">
          <div dangerouslySetInnerHTML={{ __html: props.services.html }}></div>
          {
            props.services.frontmatter.button_type === 'external' 
            ? <a 
                href={props.services.frontmatter.button_link}
                className="btn btn--purple ga-on-click-and-enter"
                target="_blank"
                rel="noopener noreferrer"
                ga='[
                  "event", "click", {
                    "event_category": "appointment",
                    "event_label": "services-appointment-button"
                  }
                ]'
              >{props.services.frontmatter.button_text}</a>
            : <Link className="btn btn--purple" to={props.services.frontmatter.button_link}>{props.services.frontmatter.button_text}</Link>
          }
        </div>
        <div className="d-flex flex-wrap col-xs-12 col-lg-7">
          { props.services.frontmatter.link_objects.map((service, idx) => (
            <LinkCard 
              key={idx}
              href={service.link}
              linkType={service.link_type !== undefined ? service.link_type : 'internal'}
            >
              <div className="link-card-container">
                <Icons name={service.icon} />
                <span>{service.text}</span>
              </div>
            </LinkCard>
          )) }
          
        </div>
      </div>
    </div>
  </div>
  </section>
)

export default styled(Services)`

  margin-bottom: 75px;
  margin-top: 75px;
  text-align: center;

  ${above.lg`
    margin-bottom: 150px;
    margin-top: 150px;
    text-align: left;
  `}

  .btn {
    margin-bottom: 25px;
  }

`;