import styled from 'styled-components';
import { colors } from '../styles/utilities';

const CardSm = styled.div`

  border: 1px solid ${ colors.purpleDark };
  color: ${ colors.purpleDark };
  margin: 0.10rem;
  padding: 0.25rem;
  display: inline-block;

`;

export default CardSm;