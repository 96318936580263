import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors } from '../styles/utilities';

const LinkCard = props => {
  return props.linkType === 'external'
    ? (
      <a className={props.className} href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    )
    : (
      <Link className={props.className} to={`/${props.href}`}>
        {props.children}
      </Link>
    )
}

export default styled(LinkCard)`
  color: ${ colors.purpleDark };
  transition: all 0.5s ease-in-out;
  text-align: right;
  flex: 1 1 auto;
  display: flex;

  &:active,
  &:focus,
  &:hover {
    //background-color: ${ colors.purpleDark };
    // border-bottom-color: ${ colors.purpleDark };
    color: ${ colors.purpleDark };
    text-decoration: none;
    cursor: pointer;

    svg g {
      fill: ${ colors.purpleDark };
    }
  }

  svg g {
    fill: #fff;
    stroke: ${ colors.purpleDark };
    stroke-width: 10;
    transition: all 0.5s ease-in-out;
  }

  &:first-of-type,
  &:nth-of-type(2) {
    //padding-top: 0;
  }

  .link-card-container {
    display: flex;
    border: 1px solid ${ colors.purpleDark };
    padding: 1rem 2rem;
    margin: 0.10rem;
    min-height: 110px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;