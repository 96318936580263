import React from 'react';
import styled from 'styled-components';
//import aos from '../images/trust/american-optometric-association-logo.png';
import Img from 'gatsby-image';

const Trust = props => (

  <div className={props.className}>
    <h2>Our Partners in Eye Health</h2>
    <div className="images">
      { props.images.edges.map((image, idx) => <Img key={idx} fixed={image.node.childImageSharp.fixed} alt={image.node.name} />) }
    </div>
  </div>

)

export default styled(Trust)`
  margin: 75px 0;
  text-align: center;
  .images {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .gatsby-image-wrapper {
      margin: 30px 15px;
    }
  }
`;