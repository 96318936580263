import React from 'react';
import { colors } from '../styles/utilities';

export default props => {

  if (props.name === 'icon-digital-eye-exam') {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="75px" viewBox="0 0 193.000000 202.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,202.000000) scale(0.100000,-0.100000)"
        fill={ colors.grayMid } stroke="none">
        <path d="M810 2004 c-129 -28 -244 -104 -326 -216 -21 -28 -59 -71 -86 -96
        -50 -46 -101 -126 -113 -174 l-7 -28 187 0 188 0 -7 60 c-4 33 -12 74 -18 91
        -17 50 3 54 58 11 124 -97 170 -113 359 -122 103 -5 162 -12 189 -24 32 -13
        77 -16 262 -16 l224 0 -6 28 c-28 116 -63 182 -138 260 -113 119 -240 194
        -383 226 -89 20 -291 20 -383 0z"/>
        <path d="M142 1400 c-45 -19 -91 -62 -114 -108 -23 -46 -23 -54 -23 -357 0
        -300 1 -311 22 -351 28 -53 83 -100 130 -113 49 -14 357 -14 405 -1 22 7 96
        57 180 123 195 153 188 136 188 428 0 125 -4 239 -9 252 -15 38 -70 96 -112
        118 -35 18 -60 19 -341 18 -167 0 -313 -4 -326 -9z m628 -325 c57 -29 76 -105
        41 -159 -66 -99 -211 -55 -211 64 0 42 15 67 53 90 38 24 78 25 117 5z m-259
        -271 c17 -8 37 -30 45 -50 12 -29 12 -39 0 -68 -37 -89 -145 -88 -183 1 -12
        31 -12 38 4 71 29 58 78 75 134 46z"/>
        <path d="M1140 1399 c-44 -18 -90 -59 -113 -102 -20 -39 -22 -57 -25 -253 -4
        -235 2 -275 49 -328 41 -48 244 -206 298 -233 41 -21 58 -23 219 -23 109 0
        190 5 216 12 53 16 121 84 135 134 7 24 11 156 11 339 0 331 -3 348 -66 407
        -60 56 -77 58 -401 57 -171 0 -309 -4 -323 -10z m141 -326 c68 -40 74 -129 13
        -182 -39 -35 -78 -40 -124 -16 -42 21 -60 53 -60 103 0 37 6 50 34 78 39 39
        89 45 137 17z m239 -270 c58 -34 67 -105 19 -152 -43 -44 -89 -43 -135 3 -46
        46 -46 86 0 132 38 38 72 43 116 17z"/>
        <path d="M749 479 c-8 -15 -8 -28 -1 -44 24 -52 276 -77 412 -41 79 21 90 30
        90 68 0 40 -20 44 -114 25 -99 -21 -193 -21 -266 -2 -77 21 -107 19 -121 -6z"/>
        <path d="M570 400 c-14 -4 -53 -8 -88 -9 l-63 -1 18 -31 c60 -103 292 -290
        423 -340 66 -25 173 -25 239 0 62 23 235 136 297 193 52 48 134 150 134 167 0
        7 -27 11 -69 11 -38 0 -82 5 -97 10 -25 10 -32 7 -83 -44 -64 -62 -216 -163
        -266 -177 -22 -6 -48 -6 -70 0 -49 13 -227 132 -280 186 -44 46 -52 49 -95 35z"/>
        </g>
      </svg>
    )

    
  }

  else if (props.name === 'icon-contacts') {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="75px" viewBox="0 0 193.000000 183.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,183.000000) scale(0.100000,-0.100000)"
        fill={ colors.grayMid } stroke="none">
        <path d="M430 1815 c-184 -51 -328 -163 -401 -311 l-30 -61 3 -214 3 -214 30
        -59 c78 -154 246 -268 444 -301 36 -6 66 -25 152 -94 l107 -86 4 -51 c13 -147
        167 -313 363 -391 66 -26 78 -28 230 -28 152 0 164 2 230 28 146 58 275 168
        332 284 l28 58 0 215 0 215 -30 60 c-41 83 -161 202 -246 243 -106 52 -225 75
        -351 69 l-106 -6 -4 137 c-3 123 -6 143 -29 192 -63 132 -200 247 -355 300
        -90 30 -289 38 -374 15z m233 -85 c207 -30 349 -132 404 -293 32 -90 28 -166
        -12 -256 -22 -48 -39 -70 -73 -93 -25 -17 -69 -53 -98 -79 -53 -48 -122 -77
        -226 -94 l-38 -6 0 -74 0 -75 -45 0 -45 0 0 78 0 77 -59 12 c-33 6 -85 22
        -115 36 -30 14 -57 23 -60 19 -3 -3 -6 -34 -6 -69 0 -35 -4 -63 -10 -63 -5 0
        -25 14 -45 31 -34 30 -35 33 -35 105 0 69 -3 78 -35 126 -19 28 -45 82 -57
        121 -19 63 -20 79 -11 134 21 118 92 224 193 288 62 39 171 70 295 83 6 1 40
        -3 78 -8z m851 -669 c138 -49 230 -125 279 -229 29 -60 32 -77 32 -157 0 -78
        -4 -98 -28 -151 -16 -34 -46 -79 -68 -100 -39 -38 -39 -39 -39 -116 l0 -77
        -37 -26 -38 -25 -3 70 c-2 38 -7 70 -11 70 -5 0 -24 -6 -43 -14 -19 -8 -71
        -20 -116 -28 l-82 -12 0 -73 0 -73 -45 0 -45 0 0 74 0 75 -57 11 c-32 6 -80
        20 -108 31 -27 11 -54 21 -60 22 -6 1 -10 -27 -10 -70 0 -45 -4 -69 -10 -65
        -5 3 -27 19 -47 36 l-38 29 0 82 c0 68 -3 85 -19 99 -10 9 -32 45 -47 79 -25
        54 -29 74 -29 152 0 77 4 98 27 148 15 32 40 73 54 90 56 67 165 131 274 162
        78 21 236 15 314 -14z"/>
        <path d="M462 1704 c-83 -18 -138 -52 -211 -130 -47 -50 -64 -77 -77 -121 -29
        -99 -7 -197 63 -290 34 -44 141 -111 214 -134 222 -69 489 48 555 245 21 63
        15 165 -14 223 -27 53 -104 132 -162 166 -89 52 -242 69 -368 41z m219 -120
        c36 -18 50 -38 56 -78 6 -44 -20 -94 -52 -102 -28 -7 -33 -22 -10 -31 22 -8
        42 -51 59 -123 l14 -61 -41 3 -41 3 -19 65 c-23 80 -40 100 -84 100 l-33 0 0
        -85 0 -85 -42 0 -43 0 0 202 0 203 60 5 c78 7 143 1 176 -16z"/>
        <path d="M530 1480 l0 -70 40 0 c56 0 95 38 86 83 -8 42 -30 57 -83 57 l-43 0
        0 -70z"/>
        <path d="M1187 1050 c-180 -57 -302 -215 -283 -363 9 -67 25 -102 75 -165 136
        -172 423 -209 624 -82 50 31 119 113 144 169 22 49 21 165 -1 215 -45 106
        -142 188 -266 227 -81 25 -213 25 -293 -1z m143 -265 l0 -175 85 0 85 0 0 -30
        0 -30 -125 0 -125 0 0 205 0 205 40 0 40 0 0 -175z"/>
        </g>
      </svg>
    )
  }

  else if (props.name === 'icon-glasses') {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="75px" viewBox="0 0 193.000000 145.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,145.000000) scale(0.100000,-0.100000)"
        fill={ colors.grayMid } stroke="none">
        <path d="M244 1429 c-63 -24 -135 -86 -170 -145 -30 -52 -74 -186 -74 -227 0
        -65 53 -121 149 -157 61 -22 62 -22 101 -5 22 10 40 21 40 25 0 4 -20 13 -44
        19 -60 15 -131 70 -131 101 0 28 48 65 106 80 25 7 292 10 766 8 l728 -3 48
        -23 c59 -30 71 -61 36 -99 -28 -29 -81 -58 -126 -68 -43 -9 -41 -18 7 -40 40
        -18 40 -18 110 9 51 20 80 39 106 69 34 40 36 45 31 97 -12 126 -81 260 -162
        315 -98 68 -67 65 -804 64 -639 0 -668 -1 -717 -20z"/>
        <path d="M302 800 c-37 -4 -70 -14 -82 -25 -26 -24 -27 -86 -1 -123 11 -15 25
        -60 31 -101 21 -129 59 -151 263 -152 132 0 200 7 237 26 41 21 107 96 132
        150 23 49 24 50 70 53 l47 3 30 -58 c33 -67 116 -149 158 -158 15 -4 38 -8 51
        -11 40 -8 276 -4 315 5 70 17 94 56 113 180 3 24 14 51 24 61 10 10 21 27 24
        38 10 32 -19 91 -51 103 -16 7 -123 14 -249 17 -206 4 -226 3 -292 -17 -94
        -28 -241 -29 -330 -1 -55 17 -90 20 -245 19 -100 -1 -210 -5 -245 -9z m414
        -91 c55 -14 68 -35 54 -87 -21 -81 -69 -102 -230 -102 -118 0 -120 0 -137 26
        -20 31 -24 127 -6 145 6 6 26 15 45 19 51 12 224 11 274 -1z m748 2 c54 -13
        61 -24 59 -92 -2 -97 -10 -101 -170 -97 -149 3 -172 13 -207 82 -26 54 -14 86
        39 102 47 15 224 17 279 5z"/>
        <path d="M20 638 c-20 -46 -22 -64 -18 -182 3 -126 4 -134 37 -200 42 -85 124
        -169 211 -216 l65 -35 630 -3 630 -3 73 37 c97 48 179 128 221 218 42 86 61
        230 42 316 -11 55 -43 120 -58 120 -5 0 -8 -35 -8 -77 0 -43 -6 -97 -13 -121
        -20 -66 -87 -142 -157 -177 l-60 -30 -660 0 -660 0 -52 24 c-64 28 -116 84
        -151 160 -21 45 -26 72 -26 138 -1 98 -13 106 -46 31z"/>
        </g>
      </svg>
    )
  }

  else if (props.name === 'icon-retinal-imaging') {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="75px" viewBox="0 0 145.000000 145.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,145.000000) scale(0.100000,-0.100000)"
        fill={ colors.grayMid } stroke="none">
        <path d="M1142 1237 c-150 -150 -212 -220 -212 -235 0 -13 11 -34 25 -47 13
        -14 34 -25 47 -25 15 0 85 62 235 212 187 186 213 216 213 244 0 38 -27 64
        -66 64 -25 0 -61 -32 -242 -213z"/>
        <path d="M913 1328 c-17 -20 -37 -268 -23 -282 24 -24 44 11 74 133 32 133 30
        153 -16 159 -14 2 -30 -3 -35 -10z"/>
        <path d="M413 1086 c-187 -47 -349 -209 -398 -395 -19 -76 -19 -207 1 -285 38
        -150 166 -299 309 -361 107 -46 257 -59 361 -31 202 54 366 225 404 424 16 78
        8 219 -15 287 -53 155 -188 291 -343 347 -73 26 -243 33 -319 14z m56 -145
        l-24 -19 43 -12 c36 -9 51 -8 88 6 121 46 281 -41 334 -183 25 -66 25 -116 1
        -169 -73 -160 -322 -122 -416 63 -11 21 -23 39 -27 41 -4 2 -24 15 -44 28
        l-37 25 7 -42 c16 -102 126 -229 231 -268 108 -41 223 -24 303 43 17 15 32 26
        32 24 0 -3 -7 -27 -17 -54 -37 -110 -116 -199 -223 -252 -50 -24 -65 -27 -170
        -27 -111 0 -117 1 -183 34 -80 40 -152 114 -194 199 -26 52 -28 66 -28 172 0
        109 2 118 32 181 43 87 127 166 214 202 35 14 72 26 83 27 16 0 15 -2 -5 -19z"/>
        <path d="M610 833 c-23 -4 -25 -8 -25 -59 0 -48 -4 -59 -27 -79 -16 -13 -28
        -33 -28 -44 0 -28 67 -101 107 -118 65 -27 143 -10 177 39 21 29 20 35 -4 41
        -23 6 -45 49 -36 72 3 9 15 19 26 22 27 7 25 16 -11 58 -49 55 -112 80 -179
        68z"/>
        <path d="M1166 961 c-110 -28 -143 -48 -119 -72 13 -13 260 6 280 23 16 13 17
        50 1 66 -16 16 -36 14 -162 -17z"/>
        </g>
      </svg>
    )
  }

  else return null;

}