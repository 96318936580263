import React from 'react';
import { Link } from 'gatsby';
import CardSm from './CardSm';
import styled from 'styled-components';
import { above, colors } from '../styles/utilities';

const CardSmList = props => ( 

  <section className={ props.className }>
    <div className="section--insurance">
      <div className="container">

        <div className="row justify-content-between">

          <div className="col-xs-12 col-lg-5">
            <div dangerouslySetInnerHTML={{__html:props.smCardList.html}} />
            { 
              props.smCardList.frontmatter.button_type && props.smCardList.frontmatter.button_type === 'external'
                ? <a
                    href={props.smCardList.frontmatter.button_link}
                    className="btn btn--purple ga-on-click-and-enter"
                    target="_blank"
                    rel="noopener noreferrer"
                    ga='[
                      "event", "click", {
                        "event_category": "appointment",
                        "event_label": "card-sm-appointment"
                      }
                    ]'
                  >{props.smCardList.frontmatter.button_text}</a>
                : <Link to={props.smCardList.frontmatter.button_link} className="btn btn--purple">{props.smCardList.frontmatter.button_text}</Link>
            }
          </div>

          <div className="d-flex flex-wrap card-sm-list col-xs-12 col-lg-7">
            <div className="card-sm-list-container">
              {
                props.smCardList.frontmatter.list.map((item, idx) => (
                  <CardSm key={idx}>{item}</CardSm>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default styled(CardSmList)`

  margin: 75px 0;
  text-align: center;

  ${above.lg`
    text-align: left;
  `}

  h2 {
    color: ${ colors.purpleDark };
  }

  .btn {
    margin-bottom: 30px;
  }

  .card-sm-list {
    justify-content: center;
    ${above.lg`
      justify-content: flex-start;
    `}
  }

  .card-sm-list-container {

  }


`;