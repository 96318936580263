import React from "react";
import { graphql } from "gatsby"
import Layout from '../layouts/default';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import Services from '../components/Services';
import StaggeredImageContent from '../components/StaggeredImageContent';
import CardSmList from '../components/CardSmList';
import Trust from '../components/Trust';
import Location from '../components/Location';
import Footer from '../components/Footer';

export default (props) => {

  return (
  <Layout menu={props.data.menu} meta={props.data.site.siteMetadata}>

    <SEO
      title={
        props.data.content.frontmatter.seo
        && props.data.content.frontmatter.seo.title
          ? props.data.content.frontmatter.seo.title
          : props.data.site.siteMetadata.title
      }
      description={
        props.data.content.frontmatter.seo
        && props.data.content.frontmatter.seo.description
          ? props.data.content.frontmatter.seo.description
          : props.data.site.siteMetadata.description
      }
    />

    <Hero
      className="hero--home"
      menu={props.data.menu}
      hero={props.data.content.frontmatter.hero}
      phoneNumber={props.data.site.siteMetadata.phoneNumber}
    />
    <Services services={props.data.services}></Services>

    {
      props.data.imageBlurbs.edges.map(({node}, i) => (
        <StaggeredImageContent 
          image={node.frontmatter.image.childImageSharp.fluid}
          imageAlt={node.frontmatter.image_alt}
          orientation={i % 2 === 0 ? 'imageFirst': 'contentFirst'}
          key={node.id}
        >
          <div dangerouslySetInnerHTML={{__html: node.html}}></div>
          <a href={node.frontmatter.button_link} className="btn btn--purple">{node.frontmatter.button_text}</a>
        </StaggeredImageContent>
      ))
    }

    <Trust images={props.data.trust} />

    <Location location={props.data.location}></Location>

    {
      props.data.sm_card_lists.edges.map(({node}, idx) => (
        <CardSmList smCardList={node} key={idx} />
      ))
    }

    <Footer menu={props.data.footerMenu} phoneNumber={props.data.site.siteMetadata.phoneNumber}></Footer>

  </Layout>
  )

}

export const query = graphql`
  query($id: String!) {

    site {
      siteMetadata {
        phoneNumber
        title
        description
        author
      }
    }

    menu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
        name: {
          eq: "main-menu"
        }
      }) {
        frontmatter {
          menu
        }
        html
    }

    services: markdownRemark(
      frontmatter: {
        type: {
          eq: "services"
        }
      }
    ){
      html
      frontmatter {
        button_link
        button_text
        button_type
        link_objects
      }
    }

    imageBlurbs: allMarkdownRemark(filter: {
      frontmatter: {
        type:{
          eq: "image-blurb"
        }
      }
    }) {
      edges {
        node {
          id
          frontmatter {
            image {
              childImageSharp {
                fluid(quality:100, maxWidth:1200) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            image_alt
            button_link
            button_text
            image_alt
          }
          html
        }
      }
    }

    location: markdownRemark(
      frontmatter: {
        name: {
          eq: "location"
        }
      }) {
      html
      frontmatter {
        button_link
        button_text
        button_type
        map
      }
    }

    sm_card_lists: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            eq: "sm-card-list"
          }
        }
      }) {
        edges {
          node {
            html
            frontmatter {
            button_link
            button_text
            button_type
            list
          }
        }
      }
    }

    content: markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        hero {
          image {
            childImageSharp {
              fluid(quality:100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          image_alt
          heading
          text
          button_link
          button_text
          link_type
        }
        seo {
          title
          description
        }
      }
    }

    footerMenu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
        name: {
          eq: "footer-menu"
        }
      }) {
        html
        frontmatter {
          menu
        }
    }

    trust: allFile(filter: {
      name: {
        in: [
          "american-optometric-association-logo",
          "florida-optometric-association",
          "lenscrafters-logo"
        ]
      }
    }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(quality: 100, width: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

  }
`;